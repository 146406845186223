// Core variables and mixins
@import 'spectre/variables';
@import 'spectre/mixins';

@import 'theme/variables';
@import 'theme/mixins';
@import 'theme/framework';
@import 'theme/typography';
@import 'theme/forms';
@import 'theme/mobile';
@import 'theme/animation';

@import 'theme/header';
@import 'theme/footer';
@import 'theme/menu';

// Extra Skeleton Styling
@import 'theme/blog';
@import 'theme/onepage';


.toc {
  display: none;
}
.centeredImage {
  margin: 60px auto 0 auto;
  display: block;
}
.owl-carousel .owl-item img {
  width: auto;
  max-height: 75vh;
  margin: auto;
}

#header {
  // overflow: hidden;
  height: 2.5rem;
  font-weight: 500;
  background-color: white !important;
}
#header .navbar .navbar-brand img {
  top: 17px;
  left: 50px;
  width: 180px;
}

.img-aligned {
  display: flex;
}
.img-aligned > div {
  flex-basis: 0;
  flex-grow: 1;

}

#modular-text img, #body-wrapper img {
  margin-top: 1rem
}

.img-aligned img {
  width: 95%;
  min-height: 120px;
  object-fit: cover;
}
