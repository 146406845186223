// Sticky Footer solution
body.sticky-footer {
  min-height: 100%;
  display: flex;
  flex-direction: column;

  #page-wrapper {
    flex: 1;
  }
}

#footer {
  color: #acb3c2;
  padding: 1rem $horiz-padding 0;
  padding-bottom: 20px;
  text-align: center;
  border-top: 3px solid #9dc1fc;
}
